import calendar from 'assets/themes/campings/js/module/searchForm/calendar'
import autocomplete from 'assets/themes/campings/js/module/searchForm/autocomplete'
import events from 'assets/themes/campings/js/module/searchForm/events'
import menus from 'assets/themes/campings/js/module/searchForm/menus'
import maxDistance from 'assets/themes/campings/js/module/searchForm/maxDistance'

document.addEventListener('DOMContentLoaded', function () {
  calendar()
  autocomplete()
  events()
  menus()
  maxDistance()
})
